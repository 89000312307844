import React, { useState } from "react"
import { graphql } from "gatsby"

import * as styles from "../type-page/type-page.module.scss"
import * as mySubscriptionsStyles from "./my-subscriptions.module.scss"
import { useFormatLocale } from "../../hooks"
import { detectWebView } from "../../utils"
import Layout from "../../components/layout/layout"
import TitleBanner from "../../components/type-page/title-banner/title-banner"
import Breadcrumb from "../../components/type-page/breadcrumb/breadcrumb"
import ShareButtons from "../../components/type-page/share-buttons/share-buttons"
import NewsBlocks from "../../components/type-page/news-blocks/news-blocks"
import SideBlocks from "../../components/type-page/side-blocks/side-blocks"

import { isNewsPublished } from "../../components/all-news/all-news"
import { RelativeLanguagePaths } from "../../components/layout/header/header-tool-bar/language-selector/language-selector"
import LoadScripts from "../../components/load-scripts"
import { MySubscriptionsPageQuery } from "../../../graphql-types"

import SchedulesSearchResults from "../../components/schedules-search/results/schedules-search-results"
import { useLinesSubscription } from "../../hooks/use-lines-subscription"

type RenderProps = {
  data: MySubscriptionsPageQuery
  location: Location
  pageContext: {
    locale: string
    defaultLocale: string
    relativeLanguagePaths: RelativeLanguagePaths
  }
}

const SubscribedLines = ({ data }) => {
  const { isSubscribed } = useLinesSubscription()
  const subscribedLines = data.allDatoCmsLine.nodes.filter(line => isSubscribed(line.id))
  const moduleConfiguration = data.datoCmsSchedulesSearchModule
  moduleConfiguration.resultsLabel = ""
  moduleConfiguration.noResultLabel = data.datoCmsMySubscriptionsPage.noLineAddedText
  return (
    <SchedulesSearchResults
      lines={subscribedLines}
      // lines={data.allDatoCmsLine.nodes}
      moduleConfiguration={moduleConfiguration}
      selectedLine={null}
      selectedMunicipality={null}
      selectedPointOfInterest={null}
    />
  )
}

const AvailableLines = ({ data }) => {
  const { isSubscribed, toggleSubscription } = useLinesSubscription()
  const AvailableLines = data.allDatoCmsLine.nodes.filter(line => !isSubscribed(line.id) && line.schedules.length > 0)
  const groupedByMethod = groupByTransportMethod(AvailableLines)

  return (
    <ul className={mySubscriptionsStyles.transportMethods}>
      {Object.keys(groupedByMethod).map(method => (
        <li key={method}>
          <h3 className={mySubscriptionsStyles.transportMethod}>
            <img
              src={groupedByMethod[method][0].transportMethod.pictogram.url}
              alt={groupedByMethod[method][0].transportMethod.pictogram.alt}
              className={mySubscriptionsStyles.transportMethodIcon}
            />
            {method.toUpperCase()}
          </h3>
          <ul className={mySubscriptionsStyles.availableLines}>
            {groupedByMethod[method].map(line => (
              <li key={line.id} className={mySubscriptionsStyles.availableLine}>
                <button
                  className={mySubscriptionsStyles.lineButton}
                  onClick={() => {
                    toggleSubscription(line.id)
                  }}
                  aria-label={`Ajouter ${line.name} à mes subscriptionses`}
                >
                  <img
                    src={line.pictogram.url}
                    alt={line.pictogram.alt}
                    className={mySubscriptionsStyles.availableLineIcon}
                  />
                </button>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}

const mySubscriptionsPage: React.FC<RenderProps> = ({ data, location, pageContext }) => {
  const twitterDisplay: boolean = data.datoCmsGlobalConfiguration.twitterDisplay
  const facebookDisplay: boolean = data.datoCmsGlobalConfiguration.fbDisplay
  const sideBlocks = data.datoCmsGlobalConfiguration.sideBlocks
  const lastThreeNewsLabel: string = data.datoCmsGlobalConfiguration.lastNewsLabel
  const lastThreeNews = data.allDatoCmsNews.nodes.filter(isNewsPublished).slice(0, 3)

  const locale = useFormatLocale(pageContext.locale)
  const defaultLocale = useFormatLocale(pageContext.defaultLocale)
  const isWebView = detectWebView()

  const [showLines, setShowLines] = useState(false)

  return (
    <Layout
      location={location}
      menuData={data.datoCmsMenu}
      footerData={data.datoCmsFooter}
      toolbarData={data.datoCmsGlobalConfiguration}
      scriptsData={data.datoCmsGlobalConfiguration}
      locale={locale}
      defaultLocale={defaultLocale}
      relativeLanguagePaths={pageContext.relativeLanguagePaths}
      mySubscriptionsPage={data.datoCmsMySubscriptionsPage}
    >
      <div className={styles.typePage}>
        {/* Localise and get the title from dato */}
        <TitleBanner title={data.datoCmsMySubscriptionsPage.title} />
        <Breadcrumb
          menuData={data.datoCmsMenu}
          pageTitle={data.datoCmsMySubscriptionsPage.title}
          locale={locale}
          defaultLocale={defaultLocale}
        />
        <div className={styles.typePageContent}>
          <div className={styles.content}>
            {isWebView ? (
              <>
                <SubscribedLines data={data} />
                <div className={mySubscriptionsStyles.linesContainer}>
                  <button
                    className={mySubscriptionsStyles.toggleLinesButton}
                    onClick={() => setShowLines(!showLines)}
                    aria-label={data.datoCmsMySubscriptionsPage.linesButtonAriaLabel}
                  >
                    <div
                      className={`${mySubscriptionsStyles.icon} ${showLines ? mySubscriptionsStyles.cross : mySubscriptionsStyles.plus}`}
                    ></div>
                  </button>

                  {showLines && (
                    <div className={mySubscriptionsStyles.linesContent}>
                      <div>{data.datoCmsMySubscriptionsPage.addLineText}</div>
                      <AvailableLines data={data} />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div>{data.datoCmsMySubscriptionsPage.notAvailableText}</div>
            )}
          </div>

          <div className={styles.rightSidebar}>
            <ShareButtons facebookDisplay={facebookDisplay} twitterDisplay={twitterDisplay}></ShareButtons>
            <NewsBlocks news={lastThreeNews} label={lastThreeNewsLabel} locale={locale} defaultLocale={defaultLocale} />
            <SideBlocks blocks={sideBlocks} locale={locale} defaultLocale={defaultLocale} />
          </div>
        </div>
      </div>
    </Layout>
  )
}
const groupByTransportMethod = lines => {
  return lines.reduce((acc, line) => {
    const method = line.transportMethod.name
    if (!acc[method]) {
      acc[method] = []
    }
    acc[method].push(line)
    return acc
  }, {})
}

export const Head = ({ data }: { data: MySubscriptionsPageQuery }) => {
  return <LoadScripts scriptData={data.datoCmsGlobalConfiguration} />
}

export const pageFragment = graphql`
  fragment MySubscriptionsPageFields on DatoCmsMySubscriptionsPage {
    isMySubscriptionsPageEnabled
    title
    linesButtonAriaLabel
    addLineText
    noLineAddedText
    notAvailableText
    popupSubscriptionTitle
    popupSubscriptionMessage
    popupUnsubscriptionTitle
    popupUnsubscriptionMessage
    popupCheckboxLabel
  }
`
export const pageQuery = graphql`
  query mySubscriptionsPage($locale: String!) {
    site {
      siteMetadata {
        title
      }
    }
    datoCmsMySubscriptionsPage(locale: $locale) {
      ...MySubscriptionsPageFields
    }
    allDatoCmsLine(locale: $locale, sort: { position: ASC }) {
      nodes {
        ...LinesListFields
        ...LostObjectsFormLineFields
      }
    }
    datoCmsSchedulesSearchModule {
      ...SchedulesSearchModuleFields
    }
    datoCmsMenu(locale: $locale) {
      ...HeaderMenuFields
      ...FooterMenuFields
      label
      label2
      label3
      label4
      column1 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
      column2 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
      column3 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
      column4 {
        page {
          slug
          content {
            ... on DatoCmsInfoTrafficModule {
              id
              model {
                name
              }
            }
          }
        }
      }
    }

    datoCmsFooter(locale: $locale) {
      ...FooterFields
    }

    datoCmsGlobalConfiguration {
      id
      twitterDisplay
      fbDisplay
      # infoTrafficTitle
      # infoTrafficDetailSlug
      sideBlocks {
        ...SideBlockFields
      }
      ...AllNewsModuleConfigurationFields
      ...HeaderToolBarFields
      ...ScriptsFields
    }
    allDatoCmsNews(locale: $locale, sort: { publicationDate: DESC }) {
      nodes {
        __typename
        id
        title
        slug
        publicationDate
        publicationStopDate
      }
    }
  }
`

export default mySubscriptionsPage
